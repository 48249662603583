<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 style="float: right">Wysparinguj sobie maila!</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2 class="mb-3">Podgląd</h2>
        <div class="preview mb-5">
          <img src="/img/preview.png" alt="" />
          <div class="preview__code" v-html="code"></div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-4">
        <h2 class="mb-4">Informacje</h2>
        <label class="form-label">Templatka</label>
        <select class="form-select mb-3" v-model="templateType">
          <option value="panowie-programisci">Panowie Programiści</option>
        </select>
        <div class="mb-3">
          <label for="name" class="form-label">Imię i Nazwisko:</label>
          <input
            type="input"
            v-model="info.name"
            class="form-control"
            id="name"
            placeholder="Adrian Hołota"
          />
        </div>
        <div class="mb-3">
          <label for="position" class="form-label">Stanowisko:</label>
          <input
            type="input"
            v-model="info.position"
            class="form-control"
            id="position"
            placeholder="head of team // co-owner"
          />
        </div>
        <div class="mb-3">
          <label for="phoneNumber" class="form-label">Numer telefonu:</label>
          <input
            type="tel"
            v-model="info.phoneNumber"
            class="form-control"
            id="phoneNumber"
            placeholder="+48 604 413 401"
          />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="addressEditDisabled">
            Blokuj edycję adresu
          </label>
          <input class="form-check-input" type="checkbox" id="addressEditDisabled" v-model="addressEditDisabled">
        </div>
        <div class="mb-3">
          <label for="address" class="form-label">Adres:</label>
          <input
            type="input"
            v-model="info.address"
            class="form-control"
            id="address"
            placeholder="Al. Jerozolimskie 47/8, Wa-wa"
            :disabled="addressEditDisabled"
          />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="includeAvailableInfoPl">
            Dołącz tekst o dostępności (PL)
          </label>
          <input class="form-check-input" type="checkbox" id="includeAvailableInfoPl" v-model="includeAvailableInfoPl">
        </div>
        <div class="mb-3">
          <label for="includeAvailableInfoPlTextarea" class="form-label">Tekst o dostępności (PL):</label>
          <textarea
            v-model="info.availableInfoPl"
            class="form-control"
            id="includeAvailableInfoPlTextarea"
            :disabled="!includeAvailableInfoPl"
          />
        </div>
        <div class="form-check">
          <label class="form-check-label" for="includeAvailableInfoEn">
            Dołącz tekst o dostępności (EN)
          </label>
          <input class="form-check-input" type="checkbox" id="includeAvailableInfoEn" v-model="includeAvailableInfoEn">
        </div>
        <div class="mb-3">
          <label for="includeAvailableInfoEnTextarea" class="form-label">Tekst o dostępności (EN):</label>
          <textarea
            v-model="info.availableInfoEn"
            class="form-control"
            id="includeAvailableInfoEnTextarea"
            :disabled="!includeAvailableInfoEn"
          />
        </div>
        <div class="mb-3">
          <label for="availableInfoColorInput" class="form-label">Kolor tekstu o dostępności:</label>
          <input type="color" class="form-control form-control-color" id="availableInfoColorInput" v-model="info.availableInfoColor">
        </div>
      </div>
      <div class="col-8">
        <h2 class="mb-3">Kod HTML</h2>
        <pre class="pre"><code class="code">{{ code }}</code></pre>
        <button
          @click="copyToClipBoard"
          type="button"
          class="btn btn-dark copy-to-clipboard-btn"
        >
          Kopiuj kod
        </button>
        <div class="copy-to-clipboard-info" v-show="isClipboardInfoVisible">
          Skopiowano do schowka!
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import panowieProgramisciTemplate from '@/templates/panowie-programisci'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { PersonInfo } from './types'

export default defineComponent({
  name: 'App',
  setup() {
    const templateType = ref('panowie-programisci')
    const info = reactive<PersonInfo>({
      name: '',
      position: '',
      phoneNumber: '',
      address: 'Al. Jerozolimskie 47/8, Wa-wa',
      availableInfoPl: 'W każdy piątek jesteśmy dostępni tylko do 13:00. Resztę dnia poświęcamy własnemu rozwojowi.',
      availableInfoEn: 'Every Friday we are available only until 1 p.m. The rest of the day is devoted to our own development.',
      availableInfoColor: '#1F89FF',
    })

    const addressEditDisabled = ref(true)
    const includeAvailableInfoPl = ref(true)
    const includeAvailableInfoEn = ref(true)

    const code = ref('')
    function renderCode() {
      code.value = panowieProgramisciTemplate(info, includeAvailableInfoPl.value, includeAvailableInfoEn.value)
    }
    watch([info, includeAvailableInfoPl, includeAvailableInfoEn], renderCode)
    onMounted(renderCode)

    const isClipboardInfoVisible = ref(false)
    async function copyToClipBoard() {
      await navigator.clipboard.writeText(code.value)
      isClipboardInfoVisible.value = true
      setTimeout(() => (isClipboardInfoVisible.value = false), 1200)
    }

    return {
      info,
      templateType,
      addressEditDisabled,
      includeAvailableInfoPl,
      includeAvailableInfoEn,
      code,
      copyToClipBoard,
      isClipboardInfoVisible
    }
  }
})
</script>

<style lang="scss" scoped>
.container {
  margin-top: 60px;
}

.pre {
  white-space: pre-wrap;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(201, 201, 201);
  padding: 10px;
}

.code {
  font-family: 'Source Code Pro', monospace;
  font-size: 12px;
}

.preview {
  border: 1px solid rgb(201, 201, 201);
  overflow: hidden;
  padding-bottom: 190px;
  position: relative;

  &__code {
    margin-left: 74px;
    position: absolute;
  }
}

.copy-to-clipboard-btn {
  float: right;
}

.copy-to-clipboard-info {
  float: right;
  margin: 5px 20px;
}
</style>
