
import panowieProgramisciTemplate from '@/templates/panowie-programisci'
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { PersonInfo } from './types'

export default defineComponent({
  name: 'App',
  setup() {
    const templateType = ref('panowie-programisci')
    const info = reactive<PersonInfo>({
      name: '',
      position: '',
      phoneNumber: '',
      address: 'Al. Jerozolimskie 47/8, Wa-wa',
      availableInfoPl: 'W każdy piątek jesteśmy dostępni tylko do 13:00. Resztę dnia poświęcamy własnemu rozwojowi.',
      availableInfoEn: 'Every Friday we are available only until 1 p.m. The rest of the day is devoted to our own development.',
      availableInfoColor: '#1F89FF',
    })

    const addressEditDisabled = ref(true)
    const includeAvailableInfoPl = ref(true)
    const includeAvailableInfoEn = ref(true)

    const code = ref('')
    function renderCode() {
      code.value = panowieProgramisciTemplate(info, includeAvailableInfoPl.value, includeAvailableInfoEn.value)
    }
    watch([info, includeAvailableInfoPl, includeAvailableInfoEn], renderCode)
    onMounted(renderCode)

    const isClipboardInfoVisible = ref(false)
    async function copyToClipBoard() {
      await navigator.clipboard.writeText(code.value)
      isClipboardInfoVisible.value = true
      setTimeout(() => (isClipboardInfoVisible.value = false), 1200)
    }

    return {
      info,
      templateType,
      addressEditDisabled,
      includeAvailableInfoPl,
      includeAvailableInfoEn,
      code,
      copyToClipBoard,
      isClipboardInfoVisible
    }
  }
})
